// eslint-disable-next-line
export default {

    'project.app.Footer.text': 'TFG - Patricia Mato Miragaya - Universidad de A Coruña',
    'project.app.Header.home': 'Home',
    'project.app.Header.logout': 'Logout',
    'project.app.Home.welcome': 'MiniPortal main page content!',

    'project.common.ErrorDialog.title': 'Error',

    'project.global.buttons.cancel': 'Cancel',
    'project.global.buttons.close': 'Close',
    'project.global.buttons.next': 'Next',
    'project.global.buttons.ok': 'OK',
    'project.global.buttons.back': 'Back',
    'project.global.buttons.save': 'Save',
    'project.global.exceptions.NetworkError': 'Network error',
    'project.global.fields.date': 'Date',
    'project.global.fields.email': 'Email address',
    'project.global.fields.firstName': 'First name',
    'project.global.fields.lastName': 'Last name',
    'project.global.fields.name': 'Name',
    'project.global.fields.password': 'Password',
    'project.global.fields.postalAddress': 'Postal address',
    'project.global.fields.postalCode': 'Postal code',
    'project.global.fields.userName': 'Username',
    'project.global.validator.email': 'Provide a correct e-mail address',
    'project.global.validator.passwordsDoNotMatch': 'Passwords do not match',
    'project.global.validator.required': 'Required field',

    'project.users.ChangePassword.fields.confirmNewPassword': 'Confirm new password',
    'project.users.ChangePassword.fields.newPassword': 'New password',
    'project.users.ChangePassword.fields.oldPassword': 'Old password',
    'project.users.ChangePassword.title': 'Change password',
    'project.users.Login.title': 'Login',
    'project.users.SignUp.fields.confirmPassword': 'Confirm password',
    'project.users.SignUp.title': 'Sign up',
    'project.users.UpdateProfile.title': 'Update profile',

    'project.products.SeeProducts.button': 'View Products',
    'project.products.ProductList.buttonPDF': 'Generate PDF',
    'project.products.ProductList.scan': 'Scan QR',
    'project.products.ProductInfo.productName': 'Name: ',
    'project.products.ProductInfo.code': 'Product Code: ',
    'project.products.ProductInfo.origin': 'Origin: ',
    'project.products.ProductInfo.price': 'Price: ',
    'project.products.ProductInfo.type': 'Product Type: ',
    'project.products.ProductInfo.subtype': 'Product Subtype: ',
    'project.products.ProductInfo.description': 'Description: ',
    'project.products.ProductInfo.location': 'Location: ',
    'project.products.ProductInfo.observations': 'Observations: ',
    'project.products.ProductInfo.state': 'State: ',
    'project.products.ProductInfo.state.noLoan': 'Available',
    'project.products.ProductInfo.state.loan': 'On Loan',
    'project.products.ProductInfo.history': 'Loan History',

    'project.products.GenerateQRPdf.button': 'Generate PDF',
    'project.products.GenerateQRPdf.selectProducts': 'Select the products you want to print the QR codes for: ',
    'project.products.GenerateQRPdf.selectSize': 'Select the size of the QR codes: ',
    'project.products.GenerateQRPdf.small': 'Small',
    'project.products.GenerateQRPdf.medium': 'Medium',
    'project.products.GenerateQRPdf.large': 'Large',

    'project.products.ProductsSearchBar.filterAll': 'All',
    'project.products.ProductsSearchBar.filterNoLoan': 'Inventory',
    'project.products.ProductsSearchBar.filterLoan': 'On Loan',

    'project.products.createProduct': 'Create product',

    'project.products.ProductForm.title': 'Enter the details of the new product',
    'project.products.ProductForm.code': 'Code: ',
    'project.products.ProductForm.productName': 'Name: ',
    'project.products.ProductForm.origin': 'Origin: ',
    'project.products.ProductForm.price': 'Price: ',
    'project.products.ProductForm.type': 'Type: ',
    'project.products.ProductForm.subtype': 'Subtype: ',
    'project.products.ProductForm.description': 'Description: ',
    'project.products.ProductForm.location': 'Location: ',
    'project.products.ProductForm.observations': 'Observations: ',
    'project.products.ProductForm.buttonCreate': 'Register new product',

    'project.loans.MembersSearchBar.noMember': 'No results found in the search',

    'project.loans.RegisterLoan.register': 'Register Loan',
    'project.loans.RegisterDevolution.register': 'Register Devolution',

    'project.loans.SearchByMember.register': 'Search by member / entity',

    'project.loans.SelectMember.search': 'Search',
    'project.loans.SelectMember.searchTitle': 'Select the member to which you want to associate the loan',
    'project.loans.SelectMember.memberTab': 'Member',
    'project.loans.SelectMember.entityTab': 'Entity',

    'project.loans.Confirmation.message': 'Are you sure you want to register the following loan?',
    'project.loans.Confirmation.confirm': 'Confirm',
    'project.loans.Confirmation.cancel': 'Cancel',
    'project.loans.Confirmation.continue': 'Continue scanning',
    'project.loans.Confirmation.member': 'Member: ',
    'project.loans.Confirmation.errorProduct': 'An error occurred retrieving the product',
    'project.loans.Confirmation.errorProduct.title': 'ERROR',

    'project.loans.LoanInfo.title': 'The loan has been registered.',
    'project.loans.LoanInfo.info': 'Loan Information',
    'project.loans.LoanInfo.date': 'Date: ',
    'project.loans.LoanInfo.homeTransport': 'Home Transport: ',
    'project.loans.LoanInfo.assumeSpent': 'Assume Expense: ',
    'project.loans.LoanInfo.amountTransport': 'Transport Amount: ',
    'project.loans.LoanInfo.observations': 'Observations: ',
    'project.loans.LoanInfo.product': 'Products: ',
    'project.loans.LoanInfo.member': 'Member: ',
    'project.loans.LoanInfo.RegisterLoan': 'Register Another Loan',
    'project.loans.LoanInfo.entityName': 'Entity: ',
    'project.loans.LoanInfo.beneficiaryName': 'Beneficiary Name: ',
    'project.loans.LoanInfo.beneficiaryLastName': 'Beneficiary Last Name: ',
    'project.loans.LoanInfo.beneficiaryTfno': 'Beneficiary Contact Phone: ',
    'project.loans.LoanInfo.beneficiaryEmail': 'Beneficiary Contact Email: ',

    'project.loans.RegisterManualLoan.selectProduct': 'Select a product',
    'project.loans.RegisterManualLoan.noProductsAvailable': 'No products available',

    'project.loans.EntityForm.title': 'Enter the loan details',
    'project.loans.EntityForm.buttonNext': 'Next',
    'project.loans.EntityForm.selectEntity': 'Select an entity',

    'project.loans.MemberInfo.birthdate': 'Birthdate: ',
    'project.loans.MemberInfo.tfno': 'Phone: ',
    'project.loans.MemberInfo.email': 'Email: ',
    'project.loans.MemberInfo.gender': 'Gender: ',
    'project.loans.MemberInfo.female': 'Female',
    'project.loans.MemberInfo.male': 'Male',
    'project.loans.MemberInfo.country': 'Country: ',
    'project.loans.MemberInfo.province': 'Province: ',
    'project.loans.MemberInfo.city': 'City: ',
    'project.loans.MemberInfo.cp': 'Postal Code: ',
    'project.loans.MemberInfo.address': 'Address: ',
    'project.loans.MemberInfo.IBAN': 'IBAN: ',
    'project.loans.MemberInfo.History': 'Loan History',
    'project.loans.MemberInfo.loan.homeTransport': 'Home Transport: ',
    'project.loans.MemberInfo.loan.assumeSpent': 'Assume Expense: ',
    'project.loans.MemberInfo.loan.amountTransport': 'Transport Amount: ',
    'project.loans.MemberInfo.loan.homeTransport.yes': 'Yes',
    'project.loans.MemberInfo.loan.homeTransport.no': 'No',
    'project.loans.MemberInfo.loan.observations': 'Observations: ',
    'project.loans.MemberInfo.loan.devolution': 'State: ',
    'project.loans.MemberInfo.loan.devolution.true': 'Returned',
    'project.loans.MemberInfo.loan.devolution.false': 'On Loan',
    'project.loans.MemberInfo.loan.devolution.button': 'Return',
    'project.loans.MemberInfo.loan.devolution.buttonAll': 'Return All',
    'project.loans.MemberInfo.noLoansMessage': 'There are no loans made',
    'project.loans.MemberInfo.history.dateSelectFrom': 'From: ',
    'project.loans.MemberInfo.history.dateSelectUntil': 'To: ',

    'project.loans.LoanItem.loan.loanUser': 'Loan made by: ',
    'project.loans.LoanItem.loan.devolutionUser': 'Devolution made by: ',

    'project.loans.MemberInfo.loan.member': 'Member: ',
    'project.loans.MemberInfo.loan.entity': 'Entity: ',

    'project.loans.ConfirmDevolution.devolve': 'Return',
    'project.loans.ConfirmDevolution.devolveAll': 'Return All',
    'project.loans.ConfirmDevolution.cancel': 'Cancel',
    'project.loans.ConfirmDevolution.title': '{memberName} has the following products on loan:',
    'project.loans.ConfirmationDevolution.message': 'Are you sure you want to finish the return?',
    'project.loans.ConfirmationDevolution.accept': 'Accept',
    'project.loans.ConfirmationDevolution.cancel': 'Cancel',
    'project.loans.ConfirmationDevolution.observations': 'Observations',
    'project.loans.ConfirmationDevolution.addObservations': 'Add observations',

    'project.loans.DevolutionInfo.title': 'The return has been registered.',
    'project.loans.DevolutionInfo.info': 'The following products have been returned: ',
    'project.loans.DevolutionInfo.observations': 'Observations: ',
    'project.loans.DevolutionInfo.Accept': 'Accept',
    'project.loans.DevolutionInfo.RegisterDevolution': 'Register Another Devolution',

    'project.loans.SelectMember.entity': 'Register entity loan',

    'project.loans.RegisterManualLoan.title': 'Enter the loan details',
    'project.loans.RegisterManualLoan.Accept': 'Register Loan',
    'project.loans.RegisterManualLoan.Cancel': 'Cancel',
    'project.loans.RegisterManualDevolution.title': 'Select the product you want to return',
    'project.loans.RegisterManualDevolution.Cancel': 'Cancel',
    'project.loans.RegisterManualDevolution.Accept': 'Register Devolution',

    'project.statistics.SeeStatistics': 'View Statistics',

    'project.qr.Generate.submitButton': 'Generate',
    'project.qr.Generate.title': 'Generate QR',
    'project.qr.Generate.idLabel': 'Product Id',
    'project.codigosQR.title': 'Scan the product QR',
    'project.codigosQR.registerManually': 'Register manually',

    'project.statistics.Map.title': 'Location of products on loan',

    'project.admin.AdminButtons.entities': 'Entities',
    'project.admin.AdminButtons.members': 'Members',
    'project.admin.AdminButtons.products': 'Products',
    'project.admin.AdminButtons.loans': 'Loans',
    'project.admin.AdminButtons.users': 'Users',
    'project.admin.SearchList.entityTitle': 'Entity Management',
    'project.admin.SearchList.memberTitle': 'Member Management',
    'project.admin.SearchList.productTitle': 'Product Management',
    'project.admin.SearchList.loanTitle': 'Loan Management',
    'project.admin.SearchList.noResult': 'No results',
    'project.admin.Confirmation.button': 'Accept',
    'project.admin.DeleteConfirmation.title': 'Are you sure you want to delete the selected item?',
    'project.admin.DeleteConfirmation.cancel': 'Cancel',
    'project.admin.DeleteConfirmation.confirm': 'Confirm',

    'project.entities.CreateEntity.title': 'Enter the details of the new entity',
    'project.entities.CreateEntity.entityName': 'Entity name: *',
    'project.entities.CreateEntity.button': 'Confirm',
    'project.admin.entities.ConfirmationEntityCreated.entityName': 'Entity name: ',
    'project.admin.entities.ConfirmationEntityCreated.title': 'The new entity has been created',
    'project.entities.EditEntity.title': 'Edit entity',
    'project.entities.EditEntity.button': 'Modify',

    'project.entities.CreateMember.title': 'Enter the details of the new member',
    'project.entities.CreateMember.firstName': 'First name: *',
    'project.entities.CreateMember.lastName': 'Last name: ',
    'project.entities.CreateMember.birthdate': 'Date of birth: ',
    'project.entities.CreateMember.tfno': 'Phone: *',
    'project.entities.CreateMember.email': 'Email: ',
    'project.entities.CreateMember.gender': 'Gender: ',
    'project.entities.CreateMember.genderFem': 'Female',
    'project.entities.CreateMember.genderH': 'Male',
    'project.entities.CreateMember.genderOther': 'Other',
    'project.entities.CreateMember.genderSelect': 'Select gender',
    'project.entities.CreateMember.country': 'Country: *',
    'project.entities.CreateMember.province': 'Province: *',
    'project.entities.CreateMember.city': 'City: *',
    'project.entities.CreateMember.cp': 'Postal Code: *',
    'project.entities.CreateMember.address': 'Address: *',
    'project.entities.CreateMember.amount': 'Annual contribution fee: *',
    'project.entities.CreateMember.iban': 'IBAN: *',

    'project.entities.EditMember.title': 'Edit member',

    'project.entities.CreateProduct.title': 'Enter the details of the new product',
    'project.entities.CreateProduct.code': 'Code: *',
    'project.entities.CreateProduct.origin': 'Origin: *',
    'project.entities.CreateProduct.price': 'Price: *',
    'project.entities.CreateProduct.type': 'Product type: *',
    'project.entities.CreateProduct.subtype': 'Product subtype: ',
    'project.entities.CreateProduct.productName': 'Name: *',
    'project.entities.CreateProduct.description': 'Description: ',
    'project.entities.CreateProduct.location': 'Location: ',
    'project.entities.CreateProduct.observations': 'Observations: ',
    'project.entities.CreateProduct.image': 'Image: ',
    'project.entities.CreateProduct.selectImage': 'Select',
    'project.entities.CreateProduct.updateImage': 'Update',

    'project.entities.EditProduct.title': 'Edit product',

    'project.entities.EditLoan.title': 'Edit loan',
    'project.entities.EditLoan.product': 'Product: *',
    'project.entities.EditLoan.member': 'Member: *',
    'project.entities.EditLoan.entity': 'Entity: *',
    'project.entities.EditLoan.dateLoan': 'Loan date: ',
    'project.entities.EditLoan.homeTransport': 'Home transport: ',
    'project.entities.EditLoan.assumeSpent': 'Assumes expense: ',
    'project.entities.EditLoan.amountTransport': 'Transport amount: ',
    'project.entities.EditLoan.observations': 'Observations: ',
    'project.entities.EditLoan.devolution': 'Devolution: ',
    'project.entities.EditLoan.entityFirstName': 'Beneficiary first name: ',
    'project.entities.EditLoan.entityLastName': 'Beneficiary last name: ',
    'project.entities.EditLoan.entityTfno': 'Beneficiary phone: ',
    'project.entities.EditLoan.entityEmail': 'Beneficiary email: ',
    'project.entities.EditLoan.state': 'State: ',
    'project.entities.EditLoan.productSelect': 'Select a product',
    'project.entities.EditLoan.memberSelect': 'Select a member',
    'project.entities.EditLoan.entitySelect': 'Select an entity',
    'project.admin.loans.LoanDetails.product': 'Product name: ',
    'project.admin.loans.LoanDetails.productCode': 'Product code: ',
    'project.admin.loans.LoanDetails.state': 'State: ',
    "project.admin.loans.LoanDetails.returned": "Returned ({date})",
    "project.admin.loans.LoanDetails.onLoan": "On loan",

    'project.admin.UserAdmin.registerUser': 'Register new user',

}
