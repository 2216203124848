// eslint-disable-next-line
export default {

    'project.app.Footer.text': 'TFG - Patricia Mato Miragaya - Universidad de A Coruña',
    'project.app.Header.home': 'Inicio',
    'project.app.Header.logout': 'Saír',
    'project.app.Home.welcome': '¡Contido da páxina principal de PA Project!',

    'project.common.ErrorDialog.title': 'Erro',

    'project.global.buttons.cancel': 'Cancelar',
    'project.global.buttons.close': 'Cerrar',
    'project.global.buttons.next': 'Seguinte',
    'project.global.buttons.ok': 'OK',
    'project.global.buttons.back': 'Anterior',
    'project.global.buttons.save': 'Gardar',
    'project.global.exceptions.NetworkError': 'Erro de comunicación',
    'project.global.fields.date': 'Data',
    'project.global.fields.email': 'Correo electrónico',
    'project.global.fields.firstName': 'Nome',
    'project.global.fields.lastName': 'Apelidos',
    'project.global.fields.name': 'Nome',
    'project.global.fields.password': 'Contrasinal',
    'project.global.fields.postalAddress': 'Dirección postal',
    'project.global.fields.postalCode': 'Código postal',
    'project.global.fields.userName': 'Usuario',
    'project.global.validator.email': 'Introduzca unha dirección de correo electrónico correcta',
    'project.global.validator.passwordsDoNotMatch': 'As contrasinais non coinciden',
    'project.global.validator.required': 'Campo obrigatorio',

    'project.users.ChangePassword.fields.confirmNewPassword': 'Confirmar contrasinal nova',
    'project.users.ChangePassword.fields.newPassword': 'Contrasinal nova',
    'project.users.ChangePassword.fields.oldPassword': 'Contrasinal antiga',
    'project.users.ChangePassword.title': 'Cambiar contrasinal',
    'project.users.Login.title': 'Autenticarse',
    'project.users.SignUp.fields.confirmPassword': 'Confirmar contrasinal',
    'project.users.SignUp.title': 'Rexistrar usuario',
    'project.users.UpdateProfile.title': 'Actualizar perfil',

    'project.products.SeeProducts.button': 'Ver produtos',
    'project.products.ProductList.buttonPDF': 'Xerar pdf',
    'project.products.ProductList.scan': 'Escanear QR',
    'project.products.ProductInfo.productName': 'Nome: ',
    'project.products.ProductInfo.code': 'Código produto: ',
    'project.products.ProductInfo.origin': 'Procedencia: ',
    'project.products.ProductInfo.price': 'Prezo: ',
    'project.products.ProductInfo.type': 'Tipo de produto: ',
    'project.products.ProductInfo.subtype': 'Subtipo de produto: ',
    'project.products.ProductInfo.description': 'Descrición: ',
    'project.products.ProductInfo.location': 'Localización: ',
    'project.products.ProductInfo.observations': 'Observacións: ',
    'project.products.ProductInfo.state': 'Estado: ',
    'project.products.ProductInfo.state.noLoan': 'Dispoñible',
    'project.products.ProductInfo.state.loan': 'En préstamo',
    'project.products.ProductInfo.history': 'Historial de préstamos',

    'project.products.GenerateQRPdf.button': 'Xerar pdf',
    'project.products.GenerateQRPdf.selectProducts': 'Seleccione os produtos para imprimir os códigos QR: ',
    'project.products.GenerateQRPdf.selectSize': 'Seleccione o tamaño dos QR: ',
    'project.products.GenerateQRPdf.small': 'Pequeno',
    'project.products.GenerateQRPdf.medium': 'Mediano',
    'project.products.GenerateQRPdf.large': 'Grande',

    'project.products.ProductsSearchBar.filterAll': 'Todos',
    'project.products.ProductsSearchBar.filterNoLoan': 'Inventario',
    'project.products.ProductsSearchBar.filterLoan': 'En préstamo',

    'project.products.createProduct': 'Crear producto',

    'project.products.ProductForm.title': 'Introduza os datos do novo produto',
    'project.products.ProductForm.code': 'Código: ',
    'project.products.ProductForm.productName': 'Nome: ',
    'project.products.ProductForm.origin': 'Procedencia: ',
    'project.products.ProductForm.price': 'Prezo: ',
    'project.products.ProductForm.type': 'Tipo: ',
    'project.products.ProductForm.subtype': 'Subtipo: ',
    'project.products.ProductForm.description': 'Descrición: ',
    'project.products.ProductForm.location': 'Localización: ',
    'project.products.ProductForm.observations': 'Observacións: ',
    'project.products.ProductForm.buttonCreate': 'Rexistrar novo produto',

    'project.loans.MembersSearchBar.noMember': 'Non se atoparon resultados na búsqueda',

    'project.loans.RegisterLoan.register': 'Rexistrar préstamo',
    'project.loans.RegisterDevolution.register': 'Rexistrar devolución',

    'project.loans.SearchByMember.register': 'Buscar por socio / entidade',

    'project.loans.SelectMember.search': 'Buscar',
    'project.loans.SelectMember.searchTitle': 'Seleccione o socio ao que desexa asociar o préstamo',
    'project.loans.SelectMember.memberTab': 'Socio',
    'project.loans.SelectMember.entityTab': 'Entidade',

    'project.loans.Confirmation.message': 'Está seguro de que desexa rexistrar o seguinte préstamo?',
    'project.loans.Confirmation.confirm': 'Confirmar',
    'project.loans.Confirmation.cancel': 'Cancelar',
    'project.loans.Confirmation.continue': 'Continuar escaneando',
    'project.loans.Confirmation.member': 'Socio: ',
    'project.loans.Confirmation.errorProduct': 'Ocorreu un erro coa obtención do produto',
    'project.loans.Confirmation.errorProduct.title': 'ERRO',

    'project.loans.LoanInfo.title': 'O préstamo quedou rexistrado.',
    'project.loans.LoanInfo.info': 'Información do préstamo',
    'project.loans.LoanInfo.date': 'Data: ',
    'project.loans.LoanInfo.homeTransport': 'Transporte ao domicilio: ',
    'project.loans.LoanInfo.assumeSpent': 'Asume gasto: ',
    'project.loans.LoanInfo.amountTransport': 'Importe transporte: ',
    'project.loans.LoanInfo.observations': 'Observacións: ',
    'project.loans.LoanInfo.product': 'Produtos: ',
    'project.loans.LoanInfo.member': 'Socio: ',
    'project.loans.LoanInfo.RegisterLoan': 'Realizar outro préstamo',
    'project.loans.LoanInfo.entityName': 'Entidade: ',
    'project.loans.LoanInfo.beneficiaryName': 'Nome beneficiario: ',
    'project.loans.LoanInfo.beneficiaryLastName': 'Apelidos beneficiario: ',
    'project.loans.LoanInfo.beneficiaryTfno': 'Teléfono contacto beneficiario: ',
    'project.loans.LoanInfo.beneficiaryEmail': 'Email contacto beneficiario: ',

    'project.loans.RegisterManualLoan.selectProduct': 'Seleccione un produto',
    'project.loans.RegisterManualLoan.noProductsAvailable': 'Non hai produtos dispoñibles',

    'project.loans.EntityForm.title': 'Introduza os datos da entidade',
    'project.loans.EntityForm.buttonNext': 'Seguinte',
    'project.loans.EntityForm.selectEntity': 'Seleccione unha entidade',

    'project.loans.MemberInfo.birthdate': 'Data de nacemento: ',
    'project.loans.MemberInfo.tfno': 'Teléfono: ',
    'project.loans.MemberInfo.email': 'Correo: ',
    'project.loans.MemberInfo.gender': 'Xénero: ',
    'project.loans.MemberInfo.female': 'Muller',
    'project.loans.MemberInfo.male': 'Home',
    'project.loans.MemberInfo.country': 'País: ',
    'project.loans.MemberInfo.province': 'Provincia: ',
    'project.loans.MemberInfo.city': 'Cidade: ',
    'project.loans.MemberInfo.cp': 'CP: ',
    'project.loans.MemberInfo.address': 'Enderezo: ',
    'project.loans.MemberInfo.IBAN': 'IBAN: ',
    'project.loans.MemberInfo.History': 'Historial de préstamos',
    'project.loans.MemberInfo.loan.homeTransport': 'Transporte ao domicilio: ',
    'project.loans.MemberInfo.loan.assumeSpent': 'Asume gasto: ',
    'project.loans.MemberInfo.loan.amountTransport': 'Importe transporte: ',
    'project.loans.MemberInfo.loan.homeTransport.yes': 'Sí',
    'project.loans.MemberInfo.loan.homeTransport.no': 'Non',
    'project.loans.MemberInfo.loan.observations': 'Observacións: ',
    'project.loans.MemberInfo.loan.devolution': 'Estado: ',
    'project.loans.MemberInfo.loan.devolution.true': 'Devolto',
    'project.loans.MemberInfo.loan.devolution.false': 'En préstamo',
    'project.loans.MemberInfo.loan.devolution.button': 'Devolver',
    'project.loans.MemberInfo.loan.devolution.buttonAll': 'Devolver todo',
    'project.loans.MemberInfo.noLoansMessage': 'Non hai préstamos realizados',
    'project.loans.MemberInfo.history.dateSelectFrom': 'Dende: ',
    'project.loans.MemberInfo.history.dateSelectUntil': 'Ata: ',

    'project.loans.LoanItem.loan.loanUser': 'Préstamo realizado por: ',
    'project.loans.LoanItem.loan.devolutionUser': 'Devolución realizada por: ',

    'project.loans.MemberInfo.loan.member': 'Socio: ',
    'project.loans.MemberInfo.loan.entity': 'Entidade: ',

    'project.loans.ConfirmDevolution.devolve': 'Devolver',
    'project.loans.ConfirmDevolution.devolveAll': 'Devolver todo',
    'project.loans.ConfirmDevolution.cancel': 'Cancelar',
    'project.loans.ConfirmDevolution.title': '{memberName} ten os seguintes produtos en préstamo:',
    'project.loans.ConfirmationDevolution.message': 'Está seguro de que desexa rematar a devolución?',
    'project.loans.ConfirmationDevolution.accept': 'Aceptar',
    'project.loans.ConfirmationDevolution.cancel': 'Cancelar',
    'project.loans.ConfirmationDevolution.observations': 'Observacións',
    'project.loans.ConfirmationDevolution.addObservations': 'Engadir observacións',

    'project.loans.DevolutionInfo.title': 'A devolución quedou rexistrada.',
    'project.loans.DevolutionInfo.info': 'Os seguintes produtos foron devoltos: ',
    'project.loans.DevolutionInfo.observations': 'Observacións: ',
    'project.loans.DevolutionInfo.Accept': 'Aceptar',
    'project.loans.DevolutionInfo.RegisterDevolution': 'Realizar outra devolución',

    'project.loans.SelectMember.entity': 'Rexistrar préstamo entidade',

    'project.loans.RegisterManualLoan.title': 'Introduza os datos do préstamo',
    'project.loans.RegisterManualLoan.Accept': 'Rexistrar préstamo',
    'project.loans.RegisterManualLoan.Cancel': 'Cancelar',
    'project.loans.RegisterManualDevolution.title': 'Seleccione o produto que desexa devolver',
    'project.loans.RegisterManualDevolution.Cancel': 'Cancelar',
    'project.loans.RegisterManualDevolution.Accept': 'Rexistrar devolución',

    'project.statistics.SeeStatistics': 'Ubicación dos produtos',

    'project.qr.Generate.submitButton': 'Xerar',
    'project.qr.Generate.title': 'Xerar QR',
    'project.qr.Generate.idLabel': 'Id do produto',
    'project.codigosQR.ScanQR.title': 'Escanee o código QR',
    'project.codigosQR.registerManually': 'Rexistrar manualmente',

    'project.statistics.Map.title': 'Ubicación dos produtos en préstamo',

    'project.admin.AdminButtons.entities': 'Entidades',
    'project.admin.AdminButtons.members': 'Socios',
    'project.admin.AdminButtons.products': 'Produtos',
    'project.admin.AdminButtons.loans': 'Préstamos',
    'project.admin.AdminButtons.users': 'Usuarios',
    'project.admin.SearchList.entityTitle': 'Xestión de entidades',
    'project.admin.SearchList.memberTitle': 'Xestión de socios',
    'project.admin.SearchList.productTitle': 'Xestión de produtos',
    'project.admin.SearchList.loanTitle': 'Xestión de préstamos',
    'project.admin.SearchList.noResult': 'Non hai resultados',
    'project.admin.Confirmation.button': 'Aceptar',
    'project.admin.DeleteConfirmation.title': 'Está seguro de que desexa eliminar o elemento seleccionado?',
    'project.admin.DeleteConfirmation.cancel': 'Cancelar',
    'project.admin.DeleteConfirmation.confirm': 'Confirmar',

    'project.entities.CreateEntity.title': 'Introduza os datos da nova entidade',
    'project.entities.CreateEntity.entityName': 'Nome da entidade: *',
    'project.entities.CreateEntity.button': 'Confirmar',
    'project.admin.entities.ConfirmationEntityCreated.entityName': 'Nome da entidade: ',
    'project.admin.entities.ConfirmationEntityCreated.title': 'A nova entidade foi creada',
    'project.entities.EditEntity.title': 'Editar entidade',
    'project.entities.EditEntity.button': 'Modificar',

    'project.entities.CreateMember.title': 'Introduza os datos do novo socio',
    'project.entities.CreateMember.firstName': 'Nome: *',
    'project.entities.CreateMember.lastName': 'Apelidos: ',
    'project.entities.CreateMember.birthdate': 'Data de nacemento: ',
    'project.entities.CreateMember.tfno': 'Teléfono: *',
    'project.entities.CreateMember.email': 'Correo electrónico: ',
    'project.entities.CreateMember.gender': 'Xénero: ',
    'project.entities.CreateMember.genderFem': 'Muller',
    'project.entities.CreateMember.genderH': 'Home',
    'project.entities.CreateMember.genderOther': 'Outro',
    'project.entities.CreateMember.genderSelect': 'Seleccione o xénero',
    'project.entities.CreateMember.country': 'País: *',
    'project.entities.CreateMember.province': 'Provincia: *',
    'project.entities.CreateMember.city': 'Cidade: *',
    'project.entities.CreateMember.cp': 'Código Postal: *',
    'project.entities.CreateMember.address': 'Enderezo: *',
    'project.entities.CreateMember.amount': 'Cuota contribución anual: *',
    'project.entities.CreateMember.iban': 'IBAN: *',

    'project.entities.EditMember.title': 'Editar socio',

    'project.entities.CreateProduct.title': 'Introduza os datos do novo produto',
    'project.entities.CreateProduct.code': 'Código: *',
    'project.entities.CreateProduct.origin': 'Procedencia: *',
    'project.entities.CreateProduct.price': 'Prezo: *',
    'project.entities.CreateProduct.type': 'Tipo de produto: *',
    'project.entities.CreateProduct.subtype': 'Subtipo de produto: ',
    'project.entities.CreateProduct.productName': 'Nome: *',
    'project.entities.CreateProduct.description': 'Descrición: ',
    'project.entities.CreateProduct.location': 'Localización: ',
    'project.entities.CreateProduct.observations': 'Observacións: ',
    'project.entities.CreateProduct.image': 'Imaxe: ',
    'project.entities.CreateProduct.selectImage': 'Seleccionar',
    'project.entities.CreateProduct.updateImage': 'Actualizar',

    'project.entities.EditProduct.title': 'Editar produto',

    'project.entities.EditLoan.title': 'Editar préstamo',
    'project.entities.EditLoan.product': 'Produto: *',
    'project.entities.EditLoan.member': 'Socio: *',
    'project.entities.EditLoan.entity': 'Entidade: *',
    'project.entities.EditLoan.dateLoan': 'Data do préstamo: ',
    'project.entities.EditLoan.homeTransport': 'Transporte a domicilio: ',
    'project.entities.EditLoan.assumeSpent': 'Asume gasto: ',
    'project.entities.EditLoan.amountTransport': 'Importe transporte: ',
    'project.entities.EditLoan.observations': 'Observacións: ',
    'project.entities.EditLoan.devolution': 'Devolución: ',
    'project.entities.EditLoan.entityFirstName': 'Nome beneficiario: ',
    'project.entities.EditLoan.entityLastName': 'Apelidos beneficiario: ',
    'project.entities.EditLoan.entityTfno': 'Teléfono beneficiario: ',
    'project.entities.EditLoan.entityEmail': 'Correo electrónico beneficiario: ',
    'project.entities.EditLoan.state': 'Estado: ',
    'project.entities.EditLoan.productSelect': 'Seleccione un produto',
    'project.entities.EditLoan.memberSelect': 'Seleccione un socio',
    'project.entities.EditLoan.entitySelect': 'Seleccione unha entidade',
    'project.admin.loans.LoanDetails.product': 'Nome do produto: ',
    'project.admin.loans.LoanDetails.productCode': 'Código do produto: ',
    'project.admin.loans.LoanDetails.state': 'Estado: ',
    "project.admin.loans.LoanDetails.returned": "Devuelto ({date})",
    "project.admin.loans.LoanDetails.onLoan": "En préstamo",

    'project.admin.UserAdmin.registerUser': 'Rexistrar novo usuario',

}
