// eslint-disable-next-line
export default {

    'project.app.Footer.text': 'TFG - Patricia Mato Miragaya - Universidad de A Coruña',
    'project.app.Header.home': 'Inicio',
    'project.app.Header.logout': 'Salir',
    'project.app.Home.welcome': 'Contenido de la página principal',

    'project.common.ErrorDialog.title': 'Error',

    'project.global.buttons.cancel': 'Cancelar',
    'project.global.buttons.close': 'Cerrar',
    'project.global.buttons.next': 'Siguiente',
    'project.global.buttons.ok': 'OK',
    'project.global.buttons.back': 'Anterior',
    'project.global.buttons.save': 'Guardar',
    'project.global.exceptions.NetworkError': 'Fallo de comunicación',
    'project.global.fields.date': 'Fecha',
    'project.global.fields.email': 'Correo electrónico',
    'project.global.fields.firstName': 'Nombre',
    'project.global.fields.lastName': 'Apellidos',
    'project.global.fields.name': 'Nombre',
    'project.global.fields.password': 'Contraseña',
    'project.global.fields.postalAddress': 'Dirección postal',
    'project.global.fields.postalCode': 'Código postal',
    'project.global.fields.userName': 'Usuario',
    'project.global.validator.email': 'Introduzca una dirección de correo electrónico correcta',
    'project.global.validator.passwordsDoNotMatch': 'Las contraseñas no coinciden',
    'project.global.validator.required': 'Campo obligatorio',

    'project.users.ChangePassword.fields.confirmNewPassword': 'Confirmar contraseña nueva',
    'project.users.ChangePassword.fields.newPassword': 'Contraseña nueva',
    'project.users.ChangePassword.fields.oldPassword': 'Contraseña antigua',
    'project.users.ChangePassword.title': 'Cambiar contraseña',
    'project.users.Login.title': 'Autenticarse',
    'project.users.SignUp.fields.confirmPassword': 'Confirmar contraseña',
    'project.users.SignUp.title': 'Registrarse',
    'project.users.UpdateProfile.title': 'Actualizar perfil',

    'project.products.SeeProducts.button': 'Ver productos',
    'project.products.ProductList.buttonPDF': 'Generar PDF',
    'project.products.ProductList.scan': 'Escanear QR',
    'project.products.ProductInfo.productName': 'Nombre: ',
    'project.products.ProductInfo.code': 'Código de producto: ',
    'project.products.ProductInfo.origin': 'Origen: ',
    'project.products.ProductInfo.price': 'Precio: ',
    'project.products.ProductInfo.type': 'Tipo de producto: ',
    'project.products.ProductInfo.subtype': 'Subtipo de producto: ',
    'project.products.ProductInfo.description': 'Descripción: ',
    'project.products.ProductInfo.location': 'Ubicación: ',
    'project.products.ProductInfo.observations': 'Observaciones: ',
    'project.products.ProductInfo.state': 'Estado: ',
    'project.products.ProductInfo.state.noLoan': 'Disponible',
    'project.products.ProductInfo.state.loan': 'En préstamo',
    'project.products.ProductInfo.history': 'Historial de préstamos',

    'project.products.GenerateQRPdf.button': 'Generar PDF',
    'project.products.GenerateQRPdf.selectProducts': 'Seleccione los productos que desea imprimir en los códigos QR: ',
    'project.products.GenerateQRPdf.selectSize': 'Seleccione el tamaño de los códigos QR: ',
    'project.products.GenerateQRPdf.small': 'Pequeño',
    'project.products.GenerateQRPdf.medium': 'Mediano',
    'project.products.GenerateQRPdf.large': 'Grande',

    'project.products.ProductsSearchBar.filterAll': 'Todos',
    'project.products.ProductsSearchBar.filterNoLoan': 'Inventario',
    'project.products.ProductsSearchBar.filterLoan': 'En préstamo',

    'project.products.createProduct': 'Crear producto',

    'project.products.ProductForm.title': 'Introduzca los datos del nuevo producto',
    'project.products.ProductForm.code': 'Código: ',
    'project.products.ProductForm.productName': 'Nombre: ',
    'project.products.ProductForm.origin': 'Procedencia: ',
    'project.products.ProductForm.price': 'Precio: ',
    'project.products.ProductForm.type': 'Tipo: ',
    'project.products.ProductForm.subtype': 'Subtipo: ',
    'project.products.ProductForm.description': 'Descripción: ',
    'project.products.ProductForm.location': 'Ubicación: ',
    'project.products.ProductForm.observations': 'Observaciones: ',
    'project.products.ProductForm.buttonCreate': 'Registrar nuevo producto',

    'project.loans.MembersSearchBar.noMember': 'No se encontraron resultados en la búsqueda',

    'project.loans.RegisterLoan.register': 'Registrar préstamo',
    'project.loans.RegisterDevolution.register': 'Registrar devolución',

    'project.loans.SearchByMember.register': 'Buscar por socio / entidad',

    'project.loans.SelectMember.search': 'Buscar',
    'project.loans.SelectMember.searchTitle': 'Selecciona el socio al que desea asociar el préstamo',
    'project.loans.SelectMember.memberTab': 'Socio',
    'project.loans.SelectMember.entityTab': 'Entidad',

    'project.loans.Confirmation.message': '¿Está seguro de que desea registrar el siguiente préstamo?',
    'project.loans.Confirmation.confirm': 'Confirmar',
    'project.loans.Confirmation.cancel': 'Cancelar',
    'project.loans.Confirmation.continue': 'Continuar escaneando',
    'project.loans.Confirmation.member': 'Socio: ',
    'project.loans.Confirmation.errorProduct': 'Ocurrió un error con la obtención del producto',
    'project.loans.Confirmation.errorProduct.title': 'ERROR',

    'project.loans.LoanInfo.title': 'El préstamo ha quedado registrado.',
    'project.loans.LoanInfo.info': 'Información del préstamo',
    'project.loans.LoanInfo.date': 'Fecha: ',
    'project.loans.LoanInfo.homeTransport': 'Transporte a domicilio: ',
    'project.loans.LoanInfo.assumeSpent': 'Asume gasto: ',
    'project.loans.LoanInfo.amountTransport': 'Importe transporte: ',
    'project.loans.LoanInfo.observations': 'Observaciones: ',
    'project.loans.LoanInfo.product': 'Productos: ',
    'project.loans.LoanInfo.member': 'Socio: ',
    'project.loans.LoanInfo.RegisterLoan': 'Realizar otro préstamo',
    'project.loans.LoanInfo.entityName': 'Entidad: ',
    'project.loans.LoanInfo.beneficiaryName': 'Nombre beneficiario: ',
    'project.loans.LoanInfo.beneficiaryLastName': 'Apellidos beneficiario: ',
    'project.loans.LoanInfo.beneficiaryTfno': 'Teléfono contacto beneficiario: ',
    'project.loans.LoanInfo.beneficiaryEmail': 'Email contacto beneficiario: ',

    'project.loans.RegisterManualLoan.selectProduct': 'Seleccione un producto',
    'project.loans.RegisterManualLoan.noProductsAvailable': 'No hay productos disponibles',

    'project.loans.EntityForm.title': 'Introduzca los datos de la entidad',
    'project.loans.EntityForm.buttonNext': 'Siguiente',
    'project.loans.EntityForm.selectEntity': 'Seleccione una entidad',

    'project.loans.MemberInfo.birthdate': 'Fecha de nacimiento: ',
    'project.loans.MemberInfo.tfno': 'Tfno: ',
    'project.loans.MemberInfo.email': 'Correo: ',
    'project.loans.MemberInfo.gender': 'Género: ',
    'project.loans.MemberInfo.female': 'Mujer',
    'project.loans.MemberInfo.male': 'Hombre',
    'project.loans.MemberInfo.country': 'País: ',
    'project.loans.MemberInfo.province': 'Provincia: ',
    'project.loans.MemberInfo.city': 'Ciudad: ',
    'project.loans.MemberInfo.cp': 'CP: ',
    'project.loans.MemberInfo.address': 'Dirección: ',
    'project.loans.MemberInfo.IBAN': 'IBAN: ',
    'project.loans.MemberInfo.History': 'Historial de préstamos',
    'project.loans.MemberInfo.loan.homeTransport': 'Transporte a domicilio: ',
    'project.loans.MemberInfo.loan.assumeSpent': 'Asume gasto: ',
    'project.loans.MemberInfo.loan.amountTransport': 'Importe transporte: ',
    'project.loans.MemberInfo.loan.homeTransport.yes': 'Sí',
    'project.loans.MemberInfo.loan.homeTransport.no': 'No',
    'project.loans.MemberInfo.loan.observations': 'Observaciones: ',
    'project.loans.MemberInfo.loan.devolution': 'Estado: ',
    'project.loans.MemberInfo.loan.devolution.true': 'Devuelto',
    'project.loans.MemberInfo.loan.devolution.false': 'En préstamo',
    'project.loans.MemberInfo.loan.devolution.button': 'Devolver',
    'project.loans.MemberInfo.loan.devolution.buttonAll': 'Devolver todo',
    'project.loans.MemberInfo.noLoansMessage': 'No hay préstamos realizados',
    'project.loans.MemberInfo.history.dateSelectFrom': 'Desde: ',
    'project.loans.MemberInfo.history.dateSelectUntil': 'Hasta: ',

    'project.loans.LoanItem.loan.loanUser': 'Préstamo realizado por: ',
    'project.loans.LoanItem.loan.devolutionUser': 'Devolución realizada por: ',

    'project.loans.MemberInfo.loan.member': 'Socio: ',
    'project.loans.MemberInfo.loan.entity': 'Entidad: ',

    'project.loans.ConfirmDevolution.devolve': 'Devolver',
    'project.loans.ConfirmDevolution.devolveAll': 'Devolver todo',
    'project.loans.ConfirmDevolution.cancel': 'Cancelar',
    'project.loans.ConfirmDevolution.title': '{memberName} tiene los siguientes productos en préstamo:',
    'project.loans.ConfirmationDevolution.message': '¿Está seguro de que desea finalizar la devolución?',
    'project.loans.ConfirmationDevolution.accept': 'Aceptar',
    'project.loans.ConfirmationDevolution.cancel': 'Cancelar',
    'project.loans.ConfirmationDevolution.observations': 'Observaciones',
    'project.loans.ConfirmationDevolution.addObservations': 'Añadir observaciones',

    'project.loans.DevolutionInfo.title': 'La devolución ha quedado registrada.',
    'project.loans.DevolutionInfo.info': 'Los siguientes productos han sido devueltos: ',
    'project.loans.DevolutionInfo.observations': 'Observaciones: ',
    'project.loans.DevolutionInfo.Accept': 'Aceptar',
    'project.loans.DevolutionInfo.RegisterDevolution': 'Realizar otra devolución',

    'project.loans.SelectMember.entity': 'Registrar préstamo entidad',

    'project.loans.RegisterManualLoan.title': 'Introduzca los datos del préstamo',
    'project.loans.RegisterManualLoan.Accept': 'Registrar préstamo',
    'project.loans.RegisterManualLoan.Cancel': 'Cancelar',
    'project.loans.RegisterManualDevolution.title': 'Seleccione el producto que desea devolver',
    'project.loans.RegisterManualDevolution.Cancel': 'Cancelar',
    'project.loans.RegisterManualDevolution.Accept': 'Registrar devolución',

    'project.statistics.SeeStatistics': 'Consultar estadísticas',

    'project.qr.Generate.submitButton': 'Generar',
    'project.qr.Generate.title': 'Generar QR',
    'project.qr.Generate.idLabel': 'Id del producto',
    'project.codigosQR.title': 'Escanee el QR del producto',
    'project.codigosQR.registerManually': 'Registrar manualmente',

    'project.statistics.Map.title': 'Ubicación de los productos en préstamo',

    'project.admin.AdminButtons.entities': 'Entidades',
    'project.admin.AdminButtons.members': 'Socios',
    'project.admin.AdminButtons.products': 'Productos',
    'project.admin.AdminButtons.loans': 'Préstamos',
    'project.admin.AdminButtons.users': 'Usuarios',
    'project.admin.SearchList.entityTitle': 'Gestión de entidades',
    'project.admin.SearchList.memberTitle': 'Gestión de socios',
    'project.admin.SearchList.productTitle': 'Gestión de productos',
    'project.admin.SearchList.loanTitle': 'Gestión de préstamos',
    'project.admin.SearchList.noResult': 'No hay resultados',
    'project.admin.Confirmation.button': 'Aceptar',
    'project.admin.DeleteConfirmation.title': '¿Está seguro de que desea eliminar el elemento seleccionado?',
    'project.admin.DeleteConfirmation.cancel': 'Cancelar',
    'project.admin.DeleteConfirmation.confirm': 'Confirmar',

    'project.entities.CreateEntity.title': 'Introduzca los datos de la nueva entidad',
    'project.entities.CreateEntity.entityName': 'Nombre de la entidad: *',
    'project.entities.CreateEntity.button': 'Confirmar',
    'project.admin.entities.ConfirmationEntityCreated.entityName': 'Nombre de la entidad: ',
    'project.admin.entities.ConfirmationEntityCreated.title': 'La nueva entidad ha sido creada',
    'project.entities.EditEntity.title': 'Editar entidad',
    'project.entities.EditEntity.button': 'Modificar',

    'project.entities.CreateMember.title': 'Introduzca los datos del nuevo socio',
    'project.entities.CreateMember.firstName': 'Nombre: *',
    'project.entities.CreateMember.lastName': 'Apellidos: ',
    'project.entities.CreateMember.birthdate': 'Fecha de nacimiento: ',
    'project.entities.CreateMember.tfno': 'Teléfono: *',
    'project.entities.CreateMember.email': 'Correo electrónico: ',
    'project.entities.CreateMember.gender': 'Género: ',
    'project.entities.CreateMember.genderFem': 'Mujer',
    'project.entities.CreateMember.genderH': 'Hombre',
    'project.entities.CreateMember.genderOther': 'Otro',
    'project.entities.CreateMember.genderSelect': 'Seleccione el género',
    'project.entities.CreateMember.country': 'País: *',
    'project.entities.CreateMember.province': 'Provincia: *',
    'project.entities.CreateMember.city': 'Ciudad: *',
    'project.entities.CreateMember.cp': 'Código Postal: *',
    'project.entities.CreateMember.address': 'Dirección: *',
    'project.entities.CreateMember.amount': 'Cuota contribución anual: *',
    'project.entities.CreateMember.iban': 'IBAN: *',

    'project.entities.EditMember.title': 'Editar socio',

    'project.entities.CreateProduct.title': 'Introduzca los datos del nuevo producto',
    'project.entities.CreateProduct.code': 'Código: *',
    'project.entities.CreateProduct.origin': 'Procedencia: *',
    'project.entities.CreateProduct.price': 'Precio: *',
    'project.entities.CreateProduct.type': 'Tipo de producto: *',
    'project.entities.CreateProduct.subtype': 'Subtipo de producto: ',
    'project.entities.CreateProduct.productName': 'Nombre: *',
    'project.entities.CreateProduct.description': 'Descripción: ',
    'project.entities.CreateProduct.location': 'Ubicación: ',
    'project.entities.CreateProduct.observations': 'Observaciones: ',
    'project.entities.CreateProduct.image': 'Imagen: ',
    'project.entities.CreateProduct.selectImage': 'Seleccionar',
    'project.entities.CreateProduct.updateImage': 'Actualizar',

    'project.entities.EditProduct.title': 'Editar producto',

    'project.entities.EditLoan.title': 'Editar préstamo',
    'project.entities.EditLoan.product': 'Producto: *',
    'project.entities.EditLoan.member': 'Socio: *',
    'project.entities.EditLoan.entity': 'Entidad: *',
    'project.entities.EditLoan.dateLoan': 'Fecha del préstamo: ',
    'project.entities.EditLoan.homeTransport': 'Transporte a domicilio: ',
    'project.entities.EditLoan.assumeSpent': 'Asume gasto: ',
    'project.entities.EditLoan.amountTransport': 'Importe transporte: ',
    'project.entities.EditLoan.observations': 'Observaciones: ',
    'project.entities.EditLoan.devolution': 'Devolución: ',
    'project.entities.EditLoan.entityFirstName': 'Nombre beneficiario: ',
    'project.entities.EditLoan.entityLastName': 'Apellidos beneficiario: ',
    'project.entities.EditLoan.entityTfno': 'Teléfono beneficiario: ',
    'project.entities.EditLoan.entityEmail': 'Correo electrónico beneficiario: ',
    'project.entities.EditLoan.state': 'Estado: ',
    'project.entities.EditLoan.productSelect': 'Seleccione un producto',
    'project.entities.EditLoan.memberSelect': 'Seleccione un socio',
    'project.entities.EditLoan.entitySelect': 'Seleccione una entidad',
    'project.admin.loans.LoanDetails.product': 'Nombre del producto: ',
    'project.admin.loans.LoanDetails.productCode': 'Código del producto: ',
    'project.admin.loans.LoanDetails.state': 'Estado: ',
    "project.admin.loans.LoanDetails.returned": "Devuelto ({date})",
    "project.admin.loans.LoanDetails.onLoan": "En préstamo",

    'project.admin.UserAdmin.registerUser': 'Registrar nuevo usuario',

}
