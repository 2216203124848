import en from './messages_en';
import es from './messages_es';
import gl from './messages_gl';

// eslint-disable-next-line
export default {
    'en': en,
    //'es': es,
    'es': gl,
    'gl': gl
};
