import {FormattedMessage} from 'react-intl';

const Footer = () => (

    <div>
        <footer className="fixed-bottom">
            <p className="text-center">

            </p>
        </footer>
    </div>

);

export default Footer;
